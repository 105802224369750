import { Container, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { PropertyForm } from "../../components/forms/PropertyForm";
import {
  usePropertyStatsSubscription,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
} from "../../__generated__/types";
import { navigate } from "gatsby";
import { translations } from "../../translations";

const PropertyByIdPage = (props) => {
  const { id } = props.params;
  const { data, loading, error } = usePropertyStatsSubscription({
    variables: { id },
  });
  const [updateProperty] = useUpdatePropertyMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (loading) return null;

  if (error) return <div>{error.message}</div>;
  return (
    <Container sx={{ mt: 3, mb: 2 }}>
      <Paper sx={{ p: 2 }}>
        <PropertyForm
          initialValues={{
            ...data?.property_by_pk,
            down_payment: 100 - data?.property_by_pk?.down_payment * 100,
          }}
          onSubmit={async (values) => {
            await updateProperty({
              variables: {
                id: id,
                address: values.address,
                bic: values.bic,
                check_in: values.check_in,
                check_out: values.check_out,
                email: values.email,
                iban: values.iban,
                legal_entity: values.legal_entity,
                legal_entity_id: values.legal_entity_id,
                name: values.name,
                phone_number: values.phone_number,
                pet_price: values.pet_price,
                down_payment: (100 - values.down_payment) / 100,
                currency_code: values.currency_code,
              },
            });
            enqueueSnackbar(
              `${translations[selected_locale].property} '${values.name}' ${translations[selected_locale].is_succesfully_updated}`,
              {
                variant: "success",
              }
            );
          }}
          onDelete={async () => {
            await deleteProperty({ variables: { id } });
            navigate("/property");
          }}
        />
      </Paper>
    </Container>
  );
};

export default PropertyByIdPage;
